export const emailContent = {
    "en": "Congratulations!<br/><br/>You've snagged yourself a seriously exclusive spot. Get ready for an evening jam-packed with thrilling surprises! Gear up to groove to the tunes of Greenlakeproject (Mollonobass & Stevan Zofski) from the 3000grad Label, Toni Haupt from TELEKOLLEGEN, and Shlomsen from Sisyphos. Alongside killer dancefloor action, brace yourself for Bingo, a Selfglitter station, and mind-blowing visuals – all for an unforgettable night. And you know it, our resident legends (Meema, Sommersonnenwende, van Staen, Lukas Edler, Nostique, Yannick, and MISSING DJ) will be unleashing some serious rock 'n' roll on the stage.<br/><br/>Partying with a purpose? Absolutely! Every dime goes straight to the Berlin City Mission.<br/><br/>Thanks to your sign-up, the doors are wide open for you. Just keep in mind, with a guest count of 200 folks, we'll be closing the door. Flash your name at the entrance, and bam, you're in. Doors open at 6 PM, all for just 15€. To lend a hand ahead of time, you can hit up one of our upcoming gigs or grab a legit ticket in person.<br/><br/>Let's dive into a magical evening together! 🎉<br/><br/>We want to emphasize, we ain't having none of that violence nonsense at our shindig. Our aim is to craft a safe and respectful space for all. We're all about that peaceful coexistence, so we're asking everyone to vibe by these principles.<br/><br/>Your Schwerelos Team",
    "de": "Herzlichen Glückwunsch!<br/><br/>Du hast dir einen wirklich exklusiven Platz gesichert. Mach dich bereit für einen Abend voller spannender Überraschungen!<br/><br/>Bereite dich darauf vor, zu den Klängen von Greenlakeproject (Mollonobass & Stevan Zofski) vom 3000grad Label, Toni Haupt von den TELEKOLLEGEN und Shlomsen aus dem Sisyphos zu feiern. Neben großartiger Dancefloor-Action erwarten dich Bingo, eine Selfglitter-Station und beeindruckende Visuals – alles für eine unvergessliche Nacht. Selbstverständlich werden auch unsere Resident-Legenden (Meema, Sommersonnenwende, van Staen, Lukas Edler, Nostique, Yannick und MISSING DJ) die Bühne ordentlich rocken.<br/><br/>Feiern und Gutes tun? Aber natürlich! Alle Einnahmen gehen direkt an die Berliner Stadtmission.<br/><br/>Dank deiner Anmeldung stehen dir die Türen weit offen. Beachte jedoch, dass bei einer Gästezahl von 200 Personen die Tür geschlossen wird. Du zeigst einfach deinen Namen an der Tür, und schwups, bist du drin. Einlass beginnt um 18 Uhr, alles für nur 15 €. Um uns bereits im Vorfeld zu unterstützen, kannst du entweder auf eine unserer kommenden Veranstaltungen oder persönlich ein echtes Ticket erwerben.<br/><br/>Lass uns gemeinsam einen zauberhaften Abend erleben! 🎉<br/><br/>Wir möchten betonen, dass auf unserer Veranstaltung keinerlei Form von Gewalt toleriert wird. Unser Ziel ist es, eine sichere und respektvolle Umgebung für alle Teilnehmer zu schaffen. Wir setzen auf ein friedliches Miteinander und bitten jeden darum, sich an diese Grundsätze zu halten.<br/><br/>Euer Schwerelos Team",
} 

export const emailContentAfterDeadline = {
    "en": "<div>Hello everyone,<br/><br/>Tomorrow is the day – our <b>'Charity Low Gravity'</b> event is right around the corner. We're really looking forward to it. Here's the latest information for you once again:<br/><br/>You've already registered, right? Just give your name at the entrance, and for €15 you'll be in.<br/><br/>We've hired talented DJs who will create a great atmosphere with their sets. You can find the exact schedule in the attachment.<br/><br/>At 9 PM, there will be a round of Bingo with the Bingo Bros – feel free to join in. For those of you in festival mode: At the glitter station, you can add a little sparkle to yourselves.<br/><br/>Since we're in the midst of nature, we kindly ask you to be mindful of the environment and not to leave any trash behind. The respective rules can be <a href='https://www.schwerelos-berlin.com/gardenrules.jpg'>found here</a>.<br/><br/>There's no place for violence here. We do not tolerate any form of aggressive behavior. Those who can't adhere to this will be escorted off the premises.<br/><br/>We're really excited for your attendance!<br/><br/>Your Schwerelos Crew 🚀</div><br/><br/><img src='https://www.schwerelos-berlin.com/pathfinder.jpg' alt='pathfinder' width='100%'><img src='https://www.schwerelos-berlin.com/timetable.jpg' alt='timetable' width='100%'>",
    "de": "<div>Hallo zusammen,<br/><br/>Morgen ist es soweit – unsere Veranstaltung <b>'Charity low gravity'</b> steht vor der Tür. Wir freuen uns wirklich sehr darauf.<br/>Hier sind noch einmal die letzten Informationen für euch:<br/><br/>Ihr habt euch ja bereits registriert, richtig? An der Kasse einfach euren Namen nennen und für 15€ seid ihr dabei.<br/><br/>Wir haben talentierte DJs engagiert, die mit ihren Sets für gute Stimmung sorgen werden. Den genauen Ablauf könnt ihr im Anhang finden.<br/><br/>Um 21 Uhr wird eine Runde Bingo mit den Bingo Brudis stattfinden – da könnt ihr gerne mitmachen. Für diejenigen unter euch, die sich im Festival-Modus befinden: An der Glitzerstation könnt ihr euch ein wenig verschönern.<br/><br/>Da wir uns inmitten der Natur befinden, bitten wir euch darum, auf die Umwelt zu achten und keinen Müll liegen zu lassen. Die entsprechenden Regeln sind <a href='https://www.schwerelos-berlin.com/gardenrules.jpg'>hier zu finden</a>.<br/><br/>Gewalt hat bei uns keinen Platz. Wir tolerieren keinerlei Form von aggressivem Verhalten. Wer sich nicht daran halten kann, wird des Geländes verwiesen.<br/><br/>Wir freuen uns wirklich sehr auf euer Kommen!<br/>Eure Schwerelos Crew 🚀<br/><br/></div><br/><br/><img src='https://www.schwerelos-berlin.com/pathfinder.jpg' alt='pathfinder' width='100%'><img src='https://www.schwerelos-berlin.com/timetable.jpg' alt='timetable' width='100%'>",
} 

export const emailContentLocation = "<div><div>(EN) In case you missed it:</div><a href='https://www.schwerelos-berlin.com/pathfinder.jpg'>Link to map (to the partylocation)</a><br/><a href='https://www.schwerelos-berlin.com/pathfinder.jpg'>Link to the Timetable</a></div><br/><div><div>(DE) Falls ihr es verpasst habt:</div><a href='https://www.schwerelos-berlin.com/pathfinder.jpg'>Link zum Party  wegfinder</a><br/><a href='https://www.schwerelos-berlin.com/pathfinder.jpg'>Link zum Timetable</a></div>";
